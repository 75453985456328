<template>
	<div>

		<carousel :autoplay="true" :loop="true" :autoplayHoverPause="false" :perPageCustom="[[1,1], [640,2], [1024,3]]" :autoplayTimeout="5000" :navigationEnabled="false" :paginationEnabled="true">

			<slide class="flex flex-col px-6 pb-4">
				<!-- <div class="-mb-20">
					<img src="/images/client-01.png" alt="Solid Horizon Sdn Bhd" class="w-[145px] h-auto mx-auto">
				</div> -->
				<div class="p-[30px] border border-gray-100 rounded-xl shadow-md text-center Xh-full">
					<p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
						‘‘We have been working with Activa Media since 2009 & our experience with them is truly rewarding.’’
					</p>
					<div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
					<h3 class="text-base italic am-blue montserrat-bol mt-5">
						Mr Desmond Chin,<br>
						Executive Chairman,
					</h3>
					<h3 class="italic montserrat-reg">Nation Employment Pte Ltd</h3>
				</div>
			</slide>

			<slide class="flex flex-col px-6 pb-4">
				<!-- <div class="-mb-20">
					<img src="/images/client-01.png" alt="Solid Horizon Sdn Bhd" class="w-[145px] h-auto mx-auto">
				</div> -->
				<div class="p-[30px] border border-gray-100 rounded-xl shadow-md text-center Xh-full">
					<p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
						‘‘Activa Media has always been very proactive and efficient in following up and attending to our requests.’’
					</p>
					<div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
					<h3 class="text-base italic am-blue montserrat-bol mt-5">
						Mr Hoh C.C,<br>
						Advocate & Solicito,
					</h3>
					<h3 class="italic montserrat-reg">HOH Law Corporation</h3>
				</div>
			</slide>

			<slide class="flex flex-col px-6 pb-4">
				<!-- <div class="-mb-20">
					<img src="/images/client-01.png" alt="Solid Horizon Sdn Bhd" class="w-[145px] h-auto mx-auto">
				</div> -->
				<div class="p-[30px] border border-gray-100 rounded-xl shadow-md text-center Xh-full">
					<p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
						‘‘Activa Media plays a big role in helping our company with new acquisition and we have definitely benefited from their campaign.’’
					</p>
					<div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
					<h3 class="text-base italic am-blue montserrat-bol mt-5">
						Mr Joseph Teo,<br>
						Chief Marketing Officer,
					</h3>
					<h3 class="italic montserrat-reg">Alniff Industries Pte Ltd</h3>
				</div>
			</slide>

			<slide class="flex flex-col px-6 pb-4">
				<!-- <div class="-mb-20">
					<img src="/images/client-01.png" alt="Solid Horizon Sdn Bhd" class="w-[145px] h-auto mx-auto">
				</div> -->
				<div class="p-[30px] border border-gray-100 rounded-xl shadow-md text-center Xh-full">
					<p class="text-sm leading-loose montserrat-reg lg:min-h-[168px]">
						‘‘My experience with Activa Media has been nothing but positive all these years. They are dedicated, conscientious and ethical professionals and it has been a real joy to work with them.’’
					</p>
					<div class="border-t-4 border-[#4678B4] w-10 mx-auto mt-5"></div>
					<h3 class="text-base italic am-blue montserrat-bol mt-5">
						A/Prof Leong Keng Hong,<br>
						Medical DirectorM,
					</h3>
					<h3 class="italic montserrat-reg">Leong Keng Hong Arthritis & Medical Clinic</h3>
				</div>
			</slide>

		</carousel>


	</div>  
</template>

<script>

export default {

  }
</script>

<style>

</style>