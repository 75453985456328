<template>
  <form @submit.prevent="submit" class="mx-auto">
    <div class="lg:flex">
    <div class="mb-2 lg:w-1/2 lg:px-1">
      <div class="flex flex-col px-2 py-1 text-left bg-white border border-gray-300 rounded">
        <label for="name" class="text-sm text-gray-500">Company Name</label>
        <input v-model="fields.company" id="name" type="text" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.company" class="text-sm font-medium text-red-600" />
    </div>
    <div class="mb-2 lg:w-1/2 lg:px-1">
      <div class="flex flex-col px-2 py-1 text-left bg-white border border-gray-300 rounded">
        <label for="name" class="text-sm text-gray-500">Name</label>
        <input v-model="fields.name" id="name" type="text" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.name" class="text-sm font-medium text-red-600" />
    </div>
    </div>
    <div class="lg:flex">
    <div class="mb-2 lg:w-1/2 lg:px-1">
      <div class="flex flex-col px-2 py-1 text-left bg-white border border-gray-300 rounded">
        <label for="contact_number" class="text-sm text-gray-500"
          >Contact Number</label
        >
        <input v-model="fields.contact" id="contact_number" type="tel" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.contact" class="text-sm font-medium text-red-600" />
    </div>
    <div class="mb-2 lg:w-1/2 lg:px-1">
      <div class="flex flex-col px-2 py-1 text-left bg-white border border-gray-300 rounded">
        <label for="email" class="text-sm text-gray-500">Email</label>
        <input v-model="fields.email" id="email" type="email" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.email" class="text-sm font-medium text-red-600" />
    </div>
    </div>
    <div class="lg:px-1">
      <div class="flex flex-col px-2 py-1 text-left bg-white border border-gray-300 rounded">
        <label for="message" class="text-sm text-gray-500">Message</label>
        <textarea
          v-model="fields.message"
          id="message"
          rows="4"
          type="text"
          class="h-auto p-0 px-0 m-0 font-sans text-base text-gray-600 border-0 focus:outline-none"
        ></textarea>
      </div>
      <InputError :field="errors.message" class="text-sm font-medium text-red-600" />
    </div>
    <div class="pt-10">
      <button
          ref="btnSubmit"
        v-text="busy ? 'Submitting...' : 'GET STARTED NOW'"
        type="submit"
        class="w-2/3 py-2 mx-auto text-lg font-medium text-white transition border-none rounded md:w-56 bg-activa-blue-default opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
      ></button>
    </div>
  </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
  mixins: [hapiMixins],
  components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/d151fc79-4341-4198-a064-595bcf8ad934",
      redirectTo: "/thank-you",
    };
  },
  mounted () {
    this.$refs.btnSubmit.addEventListener('click', ()=>{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'form-submission',
        email: this.$data.fields.email,
        contact: this.$data.fields.contact
      });
    })
  }
};
</script>
