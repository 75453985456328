<template>
    <div class="container px-4 mx-auto">
        <div class="py-5 text-xs sm:text-sm bg-white border-solid sm:text-center text-blck montserrat-reg">
            <p class="lg:mb-5 lg:leading-none">
                Website maintained by Activa Media. <span class="inline-block">All rights reserved.</span>
            </p>
        </div>
        <a target="_blank" class="wa-btn" href="https://api.whatsapp.com/send/?phone=+6583881081&text=Hi,%20I%20would%20like%20to%20enquire%20more%20on%20your%20Digital%20Marketing%20services%20and%20products.">
            <img class="h-14" src="/images/whatsapp-logo.png">
        </a>
    </div>  
</template>

<script>
export default {

}
</script>

<style>
.wa-btn{
  @apply fixed z-50 bottom-4 left-4 lg:left-8 lg:bottom-8;
  @apply flex flex-row items-center justify-center;
}
</style>