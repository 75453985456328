<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '//vxml4.plavxml.com/sited/ref/ctrk/598-44015'
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
}
</script>

<style src="./assets/tailwind.css"></style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
