<template>
    <div>

      <div class="iCountUp"></div>

    </div>
</template>

<script>
import inView from 'in-view'
import { CountUp } from 'countup.js/dist/countUp.js'

export default {
    mounted() {
      inView('.iCountUp')
      .on('enter', () => {
        const countUp = new CountUp(document.querySelector('.iCountUp'), 6500, { duration: 1 });

        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
        
      })
      .on('exit', el => {
          el.style.opacity = 1;
      });
    }
}
</script>

<style>

</style>