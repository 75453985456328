<template>
    <div>
        <h2 class="mb-5 text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]">Why Clients <span class="am-blue">Choose Us</span></h2>
        <p class="montserrat-reg">
            We’ve grown from strength to strength over the years and we’ve achieved many accolades and milestones throughout our journey.
        </p>
        <div class="justify-center pt-12 lg:pt-[60px] lg:grid lg:grid-cols-2 gap-x-[30px] space-y-[60px] lg:space-y-0 lg:gap-y-[60px]">

            <div class="" v-for="(item, i) in chooseUs" :key="i" data-aos="fade-down" :data-aos-duration="item.duration" :data-aos-delay="item.delay" data-aos-offset="120" >
                <div class="md:flex">
                    <div class="flex-none mb-5 mr-[30px]">
                        <img :src="item.image" :alt="item.alt" class="w-[120px] object-contain lg:object-top" :style="'height:'+item.ht+'px'">
                    </div>
                    <div class="space-y-5 lg:pr-[30px]">
                        <p class="text-2xl lg:text-3xl montserrat-bol lg:leading-none" :style="{ 'color': item.textColor }" v-html="item.alt"></p>
                        <p class="text-sm montserrat-reg lg:text-base" v-html="item.sub"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    data(){
        return{
            chooseUs:[
                {
                    fadeIn:"fade-right",
                    image: "images/icon-method-01.png",
                    textColor:"#1E6ECD",
                    alt: `19 Years <span class="lg:block">Experience</span>`,
                    sub: "We take the experience of building strong brands to help you with the right strategy. The long lasting relationships we’ve built with our clientele is our best testimony.",
                    duration: 750,
                    delay: 0,
                    ht: 120,
                },
                {
                    fadeIn:"fade-left",
                    image: "images/icon-method-02.png",
                    textColor:"#1E6ECD",
                    alt: `Data <span class="lg:block">Driven</span>`,
                    sub: "With an extensive database of keywords and strategy spanning across 100 industry sectors, we are experienced and committed to bringing greater value to your business.",
                    duration: 1000,
                    delay: 300,
                    ht: 120,
                },
                {
                    fadeIn:"fade-right",
                    image: "images/icon-method-03.png",
                    textColor:"#1E6ECD",
                    alt: `Full Funnel Leads Generation`,
                    sub: "Our campaigns are proven to deliver instant results and maximise returns, via high quality traffic and leads driven in various stages of the funnel.",
                    duration: 1250,
                    delay: 450,
                    ht: 150,
                },
                {
                    fadeIn:"fade-left",
                    image: "images/icon-method-04.png",
                    textColor:"#1E6ECD",
                    alt: `Guaranteed Online Presence`,
                    sub: "With our wealth of history and expertise, we know what works to help your business achieve a strong presence, reaching out to the right audiences cost effectively.",
                    duration: 1500,
                    delay: 600,
                    ht: 120,
                },
                // {
                //     fadeIn:"fade-right",
                //     image: "images/icon-method-05.png",
                //     textColor:"#fe3d00",
                //     alt: "Certified Google Premier Partner",
                //     sub: "Google Premier Partner badges are only awarded to leading companies that <b>consistently outperform</b> by <b>delivering solid ad growth</b> using Google tools."
                // },
                // {
                //     fadeIn:"fade-left",
                //     image: "images/icon-method-06.png",
                //     textColor:"#6d41ce",
                //     alt: "Guaranteed Online Presence",
                //     sub: "We have structured guidelines in <b>Planning, Strategizing and Optimizing</b> the campaign with proven records for various industries."
                // },
            ]
        }
    }
}
</script>

<style>

</style>